import React from "react";

export const useRefHeight = (ref: React.RefObject<HTMLDivElement>) => {
  const [height, setHeight] = React.useState(0);
  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setHeight(entry.contentRect.height);
      }
    });
    if (ref?.current) {
      resizeObserver.observe(ref.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [ref.current]);
  return height;
}