import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { getShortTitle } from '../../../../game/game.ts';
import { getOptionCount, type SortGame } from '../../../../game/sort/game.ts';
import {
  type GradedSubmission,
  gradeSubmission,
  type Submission,
} from '../../../../game/sort/play.ts';
import shareIcon from '../../../assets/share-icon.svg';
import { TrackEvents } from '../../analytics';
import { APIClient } from '../../api-client';
import { isWindows } from '../../lib/mobile';
import { Button } from '../design/Button';
import { HorizontalLine } from '../design/HorizontalLine';
import { GuessesComparison } from './GuessesComparison';

const buildShareableGuesses = (
  game: SortGame,
  gradedSubmissions: GradedSubmission[]
) => {
  let res = '';
  for (let optionIndex = 0; optionIndex < getOptionCount(game); optionIndex++) {
    gradedSubmissions.forEach((guess, guessIndex) => {
      if (guessIndex === 0) {
        res += NUMBER_EMOJI_MAP[String(optionIndex + 1)] + ' ';
      }
      const guessForOption = guess[optionIndex];
      res += guessForOption.isCorrect
        ? CORRECT_GUESS_EMOJI
        : INCORRECT_GUESS_EMOJI;

      const isLastGuess = guessIndex === gradedSubmissions.length - 1;
      if (!isLastGuess) {
        res += ' ';
      }
    });
    if (optionIndex !== getOptionCount(game) - 1) {
      res += '\n';
    }
  }
  return res;
};
const NUMBER_EMOJI_MAP: Record<string, string> = {
  '1': '1️⃣',
  '2': '2️⃣',
  '3': '3️⃣',
  '4': '4️⃣',
  '5': '5️⃣',
  '6': '6️⃣',
  '7': '7️⃣',
  '8': '8️⃣',
  '9': '9️⃣',
};

const CORRECT_GUESS_EMOJI = '🟢';
const INCORRECT_GUESS_EMOJI = '🔴';

interface GamePlayResultsProps {
  game: SortGame;
  submissions: Submission[];
}
export const GamePlayResults: React.FC<GamePlayResultsProps> = ({
  game,
  submissions,
}) => {
  const [resultsCopied, setResultsCopied] = React.useState(false);
  const gradedGuesses = submissions.map((submission) => {
    return gradeSubmission(game, submission);
  });

  const gameStatsQuery = useQuery({
    queryKey: ['gameStats', game.id],
    queryFn: () => new APIClient().getGameStats(game.id),
  });

  const averageGuesses = React.useMemo<number | null>(() => {
    if (gameStatsQuery.data) {
      return gameStatsQuery.data?.average_guess_count;
    }
    return null;
  }, [gameStatsQuery.data]);

  const getCopyText = () => {
    return `I just played Disorderly! - ${getShortTitle(game)} 
${location.href}

${buildShareableGuesses(game, gradedGuesses)}`;
  };

  const handleCopyButtonClick: React.MouseEventHandler<
    HTMLButtonElement
  > = () => {
    navigator.clipboard.writeText(getCopyText());
    setResultsCopied(true);
    TrackEvents.resultCopied(game);
  };

  const handleShareButtonClick: React.MouseEventHandler<
    HTMLButtonElement
  > = async () => {
    try {
      TrackEvents.resultShareClicked(game);
      await navigator.share({ text: getCopyText() });
      TrackEvents.resultShared(game);
    } catch (e) {
      // The user cancelling a share shouldn't throw an error
      if (!(e instanceof Error && e.name === 'AbortError')) {
        throw e;
      }
    }
  };

  const button =
    typeof navigator.share === 'function' && !isWindows() ? (
      <Button className="text-lg" onClick={handleShareButtonClick}>
        Share <img className="w-5 text-white" src={shareIcon.src} />
      </Button>
    ) : (
      <Button className="text-lg" onClick={handleCopyButtonClick}>
        {resultsCopied ? 'Copied!' : 'Copy Results 📄'}
      </Button>
    );

  return (
    <div className="flex flex-col gap-4 rounded-lg bg-white bg-opacity-5 p-5 text-center">
      <div className="text-xl font-bold">
        You got the answer in {submissions.length}{' '}
        {submissions.length === 1 ? 'guess' : 'guesses'}!
      </div>
      <pre className="leading-[1.1]">
        {buildShareableGuesses(game, gradedGuesses)}
      </pre>
      {button}
      {averageGuesses && (
        <>
          <HorizontalLine />
          <div className="mx-auto w-full max-w-48">
            <GuessesComparison
              user={submissions.length}
              average={averageGuesses}
            />
          </div>
        </>
      )}
    </div>
  );
};
