import React from 'react';

import type { SortGame } from '../../../../../game/sort/game.ts';
import {
  getLatestSubmission,
  gradeSubmission,
  isSubmissionCorrect,
  type SortGamePlayState,
  type Submission,
} from '../../../../../game/sort/play.ts';

interface GamePlayContextValue {
  game: SortGame;
  playState: SortGamePlayState;
  draftSubmission: Submission;
  initialOptionPositions: Record<string, number>;
}

export const GamePlayContext = React.createContext<GamePlayContextValue | null>(
  null
);

export const useGamePlayContext = () => {
  const context = React.useContext(GamePlayContext);
  if (!context) {
    throw new Error(
      'GamePlayContext must be used within a GamePlayContextProvider'
    );
  }
  const { game, playState, draftSubmission, initialOptionPositions } = context;

  const latestSubmission = getLatestSubmission(playState);

  const lockedOptions = React.useMemo(() => {
    if (!latestSubmission) {
      return [];
    }
    return gradeSubmission(game, latestSubmission)
      .filter(({ isCorrect }) => isCorrect)
      .map(({ optionKey }) => optionKey);
  }, [latestSubmission]);

  return {
    game,
    playState,
    initialOptionPositions,
    latestSubmission,
    draftSubmission,
    isOptionLocked: React.useCallback(
      (optionKey: string) => {
        return lockedOptions.includes(optionKey);
      },
      [lockedOptions]
    ),
    isSolved: React.useCallback(() => {
      return Boolean(
        latestSubmission && isSubmissionCorrect(game, latestSubmission)
      );
    }, [latestSubmission]),
  };
};
