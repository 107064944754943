import { twMerge } from 'tailwind-merge';

import instagramIcon from '../../../assets/icons/instagram.svg';
import kofiIcon from '../../../assets/icons/kofi.png';
import redditIcon from '../../../assets/icons/reddit.svg';
import xIcon from '../../../assets/icons/x.svg';
import MetaConfig from '../../../config/meta.json';
import { ButtonLink } from './Button';

export const SocialButton = ({
  url,
  iconSrc,
  className,
  iconClassName,
  message,
}: {
  url: string;
  iconSrc: string;
  message: string;
  className?: string;
  iconClassName?: string;
}) => {
  return (
    <ButtonLink
      href={url}
      target="_blank"
      className={twMerge(
        'flex min-w-6 gap-4 rounded-lg bg-gradient-to-r text-lg font-semibold',
        className
      )}
    >
      <img
        src={iconSrc}
        className={twMerge('inline-block w-5', iconClassName)}
      />
      <span>{message}</span>
    </ButtonLink>
  );
};

/* https://about.instagram.com/brand/gradient */
export const InstagramSocialButton = () => {
  return (
    <SocialButton
      url={MetaConfig.links.instagram}
      iconSrc={instagramIcon.src}
      message="Follow us on Instagram"
      className="from-[#D300C5] via-[#FF0069] to-[#FF7A00]"
    />
  );
};

export const XSocialButton = () => {
  return (
    <SocialButton
      url={MetaConfig.links.x}
      iconSrc={xIcon.src}
      message="Follow us on X"
      className="bg-zinc-900"
    />
  );
};

/* https://reddit.lingoapp.com/s/R7y72J/?v=21 */
export const RedditSocialButton = () => {
  return (
    <SocialButton
      url={MetaConfig.links.reddit}
      iconSrc={redditIcon.src}
      message="Join us on Reddit"
      className="from-[#FF4500] from-40% to-[#FF8864]"
      iconClassName="w-6"
    />
  );
};

/*https://more.ko-fi.com/brand-assets*/
export const KofiSocialButton = () => {
  return (
    <SocialButton
      url={MetaConfig.links.kofi}
      iconSrc={kofiIcon.src}
      message="Support us on Ko-fi"
      className="bg-[#13C3FF]"
      iconClassName="w-8"
    />
  );
};
