import classNames from 'classnames';

import { HorizontalBarChart } from '../stats/BarChart';

export interface GuessesComparisonProps {
  user: number;
  average: number;
}

export const GuessesComparison: React.FC<GuessesComparisonProps> = ({
  user,
  average,
}) => {
  return (
    <HorizontalBarChart
      bars={[
        {
          label: 'You',
          value: user,
          className: classNames(
            'animate-width-grow',
            user > average ? 'bg-red-500' : 'bg-green-400'
          ),
        },
        {
          label: 'Avg.',
          value: average,
          className: 'animate-width-grow bg-gray-300',
        },
      ]}
      valueLabel={(value) => value.toFixed(1)}
    />
  );
};
