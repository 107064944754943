import confetti from 'canvas-confetti';
import React from 'react';

import {
  getCorrectAnswer,
  getOptionKeys,
  type SortGame,
} from '../../../../../game/sort/game.ts';
import {
  getLatestSubmission,
  isGameComplete,
  isSubmissionCorrect,
  type SortGamePlayState,
  type Submission,
} from '../../../../../game/sort/play.ts';
import { areArraysEqual } from '../../../../../lib/array.ts';
import { DisorderlyTheme } from '../../../../../theme/theme.ts';
import { findBy, shuffleArray } from '../../../../lib/array.ts';
import { TrackEvents } from '../../../analytics.ts';
import { GamePlayStorage } from '../../../storage/game-play-storage.ts';
import { Button } from '../../design/Button.tsx';
import { ContentContainer } from '../../design/ContentContainer.tsx';
import { KofiSocialButton } from '../../design/SocialButton.tsx';
import { Tile } from '../../design/Tile.tsx';
import { GamePlayResults } from '../../game-play-results/GamePlayResults.tsx';
import { GameTitle } from '../GameTitle.tsx';
import { GameMeta } from './GameMeta.tsx';
import { GamePlayContext } from './GamePlayContext.ts';
import { GuessComposer } from './GuessComposer.tsx';
import { Marker } from './Marker.tsx';

interface SortGamePlayerProps {
  game: SortGame;
  averageGuesses?: number | null;
}

export const SortGamePlayer: React.FC<SortGamePlayerProps> = ({ game }) => {
  const [playState, setPlayState] = React.useState<SortGamePlayState>(() => {
    return { submissions: GamePlayStorage.get(game)?.submittedGuesses ?? [] };
  });
  const [draftSubmission, setDraftSubmission] = React.useState<Submission>(
    () => {
      return playState.submissions.length > 0
        ? playState.submissions[playState.submissions.length - 1]
        : shuffleArray(getOptionKeys(game));
    }
  );
  const [initialOptionPositions] = React.useState<Record<string, number>>(
    draftSubmission.reduce(
      (acc, curr, i) => {
        acc[curr] = i;
        return acc;
      },
      {} as Record<string, number>
    )
  );

  const gameComplete = isGameComplete(game, playState);

  React.useEffect(() => {
    if (gameComplete) {
      GamePlayStorage.set(game, { submittedGuesses: playState.submissions });
    }
  }, [gameComplete]);

  const latestGuess = getLatestSubmission(playState);
  const canSubmit =
    !latestGuess || !areArraysEqual(latestGuess, draftSubmission);

  const submitGuess = (guess: Submission, skipAnalytics = false) => {
    const newSubmissions = [...playState.submissions, guess];
    setPlayState({ submissions: newSubmissions });
    setDraftSubmission([...guess]);

    if (isSubmissionCorrect(game, guess)) {
      const prefersReducedMotion = window.matchMedia(
        '(prefers-reduced-motion: reduce)'
      ).matches;

      const isSmallScreen = window.matchMedia('(max-width: 640px)').matches;
      if (!prefersReducedMotion) {
        confetti({
          colors: [...Object.values(DisorderlyTheme.colors.core)],
          scalar: 0.8,
          particleCount: 100,
          origin: { y: isSmallScreen ? 0.7 : 0.5 },
        });
      }
      if (!skipAnalytics) {
        TrackEvents.gameSolved(game, newSubmissions.length);
      }
    }
  };

  const handleDraftGuessChange = (guess: Submission) => {
    setDraftSubmission(guess);
  };

  const handleGuessSubmit = () => {
    submitGuess(draftSubmission);
  };

  React.useEffect(() => {
    const cb = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === 's') {
        submitGuess(getCorrectAnswer(game), true);
      }
    };
    document.addEventListener('keydown', cb);
    return () => document.removeEventListener('keydown', cb);
  }, [playState.submissions]);

  const funFact = findBy(game.attributes ?? [], 'name', 'fun-fact')?.value;

  return (
    <GamePlayContext.Provider
      value={{
        game: game,
        playState,
        draftSubmission,
        initialOptionPositions,
      }}
    >
      <ContentContainer className="p-0">
        <div className="px-2 sm:mb-8 sm:mt-4">
          <GameTitle>{game.title}</GameTitle>

          {game.content.details && (
            <div className="text-subtle my-1 text-xs italic sm:text-base">
              {game.content.details}
            </div>
          )}
        </div>
        {game.content.markers && (
          <div className="mb-1">
            <Marker>{game.content.markers[0]}</Marker>
          </div>
        )}
        <div>
          <GuessComposer onChange={handleDraftGuessChange} />
        </div>
        {game.content.markers && (
          <div className="mt-1">
            <Marker>{game.content.markers[1]}</Marker>
          </div>
        )}
        {!gameComplete && (
          <div className="w-full px-2">
            <Button
              className="mt-4 text-lg sm:mt-8"
              onClick={handleGuessSubmit}
              disabled={!canSubmit}
            >
              Submit Guess
            </Button>
          </div>
        )}
        <div className="mt-8 px-2 sm:px-3">
          {gameComplete && funFact && (
            <Tile className="mt-2" contentClassName="p-3" color="#38bdf8">
              <div className="text-xl font-bold">🥳 Just for fun</div>
              <div className="mt-2">{funFact}</div>
            </Tile>
          )}
          {gameComplete && (
            <div className="mt-4">
              <GamePlayResults
                game={game}
                submissions={playState.submissions}
              />
            </div>
          )}
          {gameComplete && (game.sources?.length ?? 0) > 0 && (
            <details className="text-subtle mt-2 flex flex-col gap-1 text-sm">
              <summary>Sources</summary>
              <ul>
                {game.sources?.map((source) => (
                  <li key={source}>
                    -{' '}
                    <a
                      href={source}
                      target="_blank"
                      className="text-subtle text-xs"
                    >
                      {source}
                    </a>
                  </li>
                ))}
              </ul>
            </details>
          )}
          <div className="my-16">
            {gameComplete && (
              <div className="mt-8">
                <KofiSocialButton />
              </div>
            )}
          </div>
          <div>
            <GameMeta publishedAt={game.publishedAt} />
          </div>
        </div>
      </ContentContainer>
    </GamePlayContext.Provider>
  );
};
