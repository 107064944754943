import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import React, { forwardRef } from 'react';

import type { Option } from '../../../../../game/sort/game.ts';
import { Tile } from '../../design/Tile.tsx';
import styles from './GuessComposer.module.css';
import { useSortStrategy } from './sort-strategy.ts';

interface OptionTileContentProps {
  option: Option;
  showRevealDetails?: boolean;
}

export const OptionTileContent: React.FC<OptionTileContentProps> = ({
  option,
  showRevealDetails,
}) => {
  const renderDetails = Boolean(
    option.details || (showRevealDetails && option.revealDetails)
  );
  const renderInitialDetails = Boolean(option.details);
  const renderRevealDetails = Boolean(
    showRevealDetails && option.revealDetails
  );

  return (
    <div className="flex flex-col justify-center">
      <div className="min-w-[70%] text-sm font-bold sm:text-xl mb-1">
        {option.name}
      </div>
      {renderDetails && (
        <div className="flex gap-1 text-sm">
          {renderRevealDetails && (
            <div className="text-xs opacity-90 sm:text-sm [&:not(:last-child)]:after:px-1 [&:not(:last-child)]:after:content-['•']">
              {option.revealDetails}
            </div>
          )}
          {renderInitialDetails && (
            <div className="text-xs opacity-70 sm:text-sm">
              {option.details}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

interface OptionTileDummyProps {
  option: Option;
  position: number;
  height: number;
}

export const OptionTileDummy: React.FC<OptionTileDummyProps> = ({
  option,
  position,
  height,
}) => {
  return (
    <div className="flex touch-manipulation" style={{ height }}>
      <Tile
        full
        color="var(--color)"
        data-position={position}
        isHoverable
        isDraggable
      >
        <span data-position={position}>
          <span className="flex w-2 shrink-0 grow-0 items-center justify-center"></span>
        </span>
        <OptionTileContent option={option} />
      </Tile>
    </div>
  );
};

interface OptionTileProps {
  className?: string;
  option: Option;
  locked: boolean;
  position: number;
  dimmed: boolean;
  shake: boolean;
  showRevealDetails: boolean;
  disableDND?: boolean;
}

export const OptionTile = forwardRef<HTMLDivElement, OptionTileProps>(
  (
    { option, locked, position, dimmed, shake, showRevealDetails, className },
    ref
  ) => {
    const {
      active,
      over,
      attributes,
      listeners,
      setNodeRef,
      transition,
      transform,
    } = useSortable({
      id: option.key,
      disabled: {
        draggable: locked,
        droppable: locked,
      },
    });

    const isActive = active?.id === option.key;
    const isOver = over?.id === option.key;

    const { getItemBehavior } = useSortStrategy();
    const { useTransform } = getItemBehavior(option.key, isActive, isOver);

    const style = {
      transition,
      transform: useTransform ? CSS.Transform.toString(transform) : undefined,
    };
    return (
      <div
        ref={setNodeRef}
        className={classNames(
          'flex touch-manipulation',
          active?.id === option.key && 'invisible [&_*]:invisible',
          shake && !locked && styles.shake,
          className
        )}
        style={style}
        {...attributes}
        {...listeners}
      >
        <Tile
          ref={ref}
          full
          isDimmed={dimmed}
          color={locked ? 'var(--color-correct)' : 'var(--color)'}
          data-position={position}
          isHoverable={!locked}
          isDraggable={!locked}
        >
          <OptionTileContent
            option={option}
            showRevealDetails={showRevealDetails}
          />
        </Tile>
      </div>
    );
  }
);
