type SortingMode = "default" | "swap";

interface SettingsJSON {
    sortingMode?: SortingMode;
}

class Settings {
    json: SettingsJSON;
    constructor(json: SettingsJSON) {
        this.json = json;
    }

    getSortingMode() {
        return this.json.sortingMode ?? "default";
    }

    setSortingMode(mode: SortingMode) {
        this.json.sortingMode = mode;
        this.write();
    }
    
    private write() {
        localStorage.setItem("settings", JSON.stringify(this.json));
    }
}

const storedSettings = localStorage.getItem("settings");
export const settings = new Settings(storedSettings ? JSON.parse(storedSettings) : {});
((window as any)['__dev__'] as any) ??= {};
((window as any)['__dev__'].settings as any)= settings;


