interface MarkerProps {
  className?: string;
  children: React.ReactNode;
}

export const Marker: React.FC<MarkerProps> = ({ children }) => {
  return (
    <div className="text-subtle mr-2 text-nowrap text-right text-sm font-semibold italic sm:text-base">
      {children}
    </div>
  );
};
